<template>
  <Section>
    <Container>
      <div class="title-bar margin-bottom">
        <h2>{{ pageTitle }} <span>({{ trials?.length }}&nbsp;essais et {{ selectedVariables.length }}&nbsp;variables)</span></h2>
      </div>
      <Input class="capped-width" v-if="grouping" label="Nom du regroupement" v-model="grouping.nom" id="name" required :text-error="errors.nom"/>
      <Radio class="capped-width" v-if="grouping" label="Type d'analyse"
        v-model="grouping.type" id="type" required :text-error="errors.type"
        :items="[
          {label: 'Rapport en données brutes', value: 1},
          {label: 'Rapport en données moyenneées', value: 2}
        ]"
        @update:modelValue="setType($event)"
        inputStyle="inline"
      />
    </Container>
  </Section>

  <Section>
    <Container>
      <h3>{{ trials?.length }}&nbsp;essai{{ trials?.length > 1 ? 's' : '' }}</h3>
    </Container>
    <List v-if="trials.length" :header="list.headers" :items="list.col" :item-key="list.key" :list-data="trials" disableGroupActions
      disableActions disableCheckbox>

      <template v-slot:culture="{ item }">
        <template v-if="item.protocole.cultures.length > 0">
          <div class="tags">
            <MiniTag v-for="culture in item.protocole.cultures" :key="culture.id" :id="culture.uid"
              :text="culture.nom" />
          </div>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>

      <template v-slot:modalites="{ item }">
        <template v-if="item.modalities">
          {{ item.modalities.length }}
        </template>
      </template>

      <template v-slot:variables="{ item }">
        <template v-if="item.variables">
          {{ item.variables.join(', ') }}
        </template>
      </template>

      <template v-slot:entite="{ item }">
        {{ item.protocole?.entite?.nom }}
      </template>

      <template v-slot:annee="{ item }">
        {{ item.protocole?.annee_recolte?.valeur }}
      </template>

    </List>
  </Section>

  <Section>
    <Container>
      <h3>{{ selectedVariables.length }}&nbsp;variable{{ selectedVariables?.length > 1 ? 's' : '' }}</h3>
      <div class="grid grid--compact capped-width">
        <div class="variable" v-for="(variable, i) in selectedVariables" :key="i">
          {{ variable.designation }}
        </div>
      </div>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Input from '@/components/form/Input.vue'
import List from '@/components/list/List.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Radio from '@/components/form/Radio.vue'

export default {
  name: 'GroupingContentAddEditView',
  components: {
    Container,
    Section,
    Input,
    List,
    MiniTag,
    Radio,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    groupingProp: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      errors: {},
      trials: [],
      modalities: [],
      variables: [],
      selectedVariables: [],
      grouping: this.groupingProp,
      routeParams: {
        id: this.$route.params.id,
        rid: this.$route.params.rid,
      },
      list: {
        headers: [
          { label: 'Nom de l\'essai' },
          { label: 'Année' },
          { label: 'Culture' },
          { label: 'Entité' },
          { label: 'Modalités' },
          { label: 'Variables' },
        ],
        col: ['nom', 'annee', 'culture', 'entite', 'modalites', 'variables'],
        key: 'id',
      },
    }
  },
  emits: ['set-step', 'set-route', 'update:modelValue', 'set-grouping'],
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.handleValues()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    this.$emit('set-step', 3)
    this.$emit('set-route', {
      previousRouteName: 'groupingVariablesAdd',
      routeParams: this.routeParams,
    })

    this.fetchService.get(`protocole/${this.$route.params.id}/regroupement_essai/${this.$route.params.rid}`).then((response) => {
      this.grouping = response.data
      this.trials = this.grouping?.essais
      this.modalities = this.grouping?.modalites
      this.variables = this.grouping?.variables
      this.selectedVariables = this.setSelectedVariables()
      this.alterTrials()
      this.grouping.type = 1
      this.$emit('set-grouping', this.grouping ?? {})
    })
  },
  methods: {
    setType(event) {
      this.grouping.type = parseInt(event, 10)
    },
    alterTrials() {
      this.trials.forEach((trial) => {
      // eslint-disable-next-line
      trial.modalities = this.modalities.filter(modality => {
          return Object.values(modality.essais).some((essai) => essai.id === trial.id && essai.selected === true)
        })
        // eslint-disable-next-line
      trial.variables = this.variables.filter(variable => {
          return variable.essais ? Object.values(variable.essais).some((essai) => essai.id === trial.id && essai.selected === true) : false
        }).map((v) => v.designation)
      })
    },
    setSelectedVariables() {
      return this.variables.filter((variable) => (variable.essais ? Object.values(variable.essais).some((essai) => essai.selected === true) : false))
    },
    handleValues() {
      const params = {}
      params.nom = this.grouping.nom
      params.type = this.grouping.type
      if (!params.nom) {
        this.errors.nom = 'Ce champ est obligatoire'
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Le champ nom est obligatoire.',
        })
      } else if (!params.type) {
        this.errors.type = 'Ce champ est obligatoire'
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Le champ type d\'analyse est obligatoire.',
        })
      } else {
        this.emitter.emit('open-loader')
        const endpoint = `protocole/${this.$route.params.id}/regroupement_essai/${this.grouping.id}`

        const putGrouping = () => {
          this.fetchService.put(endpoint, params)
            .then(() => {
              const routeParams = { id: this.$route.params.id }
              this.emitter.emit('list-refresh')
              this.$router.push({ name: 'protocolEssaisGroupings', params: routeParams })
              this.fetchService.patch(`protocole/${this.$route.params.id}/regroupement_essai/${this.grouping.id}/statistiques`)
                .then(() => {
                  this.emitter.emit('alert', {
                    type: 'success',
                    content: 'Le rapport est en cours de génération.',
                  })
                })
            })
            .catch(() => {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une erreur s\'est produite lors de la mise à jour du regroupement.',
              })
            })
            .finally(() => {
              this.emitter.emit('close-loader')
            })
        }

        putGrouping()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

h2 {
  > span {
    color: $color-gray;
    font-weight: $font-weight-normal;
  }
}

h3 {
  margin-bottom: $gutter;
}

.variable {
  font-weight: $font-weight-semibold;
}

.capped-width {
  max-width: 60rem;
}

</style>
